body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", "Open Sans", Calibri, Candara, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.1;
  font-style: normal;
}

h1 {
  font-size: calc(35px + 0.390625vw);
  color: rgb(102, 102, 102);
}

h2 {
  font-size: calc(30px + 0.390625vw);
  color: rgb(102, 102, 102);
}

div,
th,
td {
  font-size: 14px;
  color: rgb(102, 102, 102);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
